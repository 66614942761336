import { Post } from '@model/type';
import React, { useEffect, useState } from 'react';
import PostCard from './PostCard';
import './PostList.scss';

interface PostListProps {
    posts: Post[];
    category?: string;
}

const PostList: React.FC<PostListProps> = ({ posts, category = '' }) => {
    const [postsPerRow, setPostsPerRow] = useState(3);

    useEffect(() => {
        const updatePostsPerRow = () => {
            const width = window.innerWidth;
            if (width < 620) {
                setPostsPerRow(1);
            } else if (width < 768) {
                setPostsPerRow(2);
            } else {
                setPostsPerRow(3);
            }
        };

        window.addEventListener('resize', updatePostsPerRow);

        updatePostsPerRow();

        return () => {
            window.removeEventListener('resize', updatePostsPerRow);
        };
    }, []);

    return (
        <div className="post-list">
            <h1 className="post-list-title">{category}</h1>
            <div className="post-list-content">
                {posts.map((post, index) => (
                    <React.Fragment key={index}>
                        <PostCard
                            title={post.title}
                            date={post.date}
                            tags={post.tags}
                            slug={post.slug}
                        />
                        {postsPerRow > 0 &&
                            posts.length > 1 &&
                            (index + 1) % postsPerRow === 0 &&
                            index + 1 !== posts.length && <hr className="post-divider" />}
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
};

export default PostList;
