import React, { FC } from 'react';
import './Pagination.scss';

interface PaginationProps {
    postsNum: number;
    page: number;
    prefixUrl: string;
}

const Pagination: FC<PaginationProps> = ({ postsNum, page, prefixUrl }) => {
    const itemsPerPage = 9;
    return (
        <nav className={`pagination ${page <= 1 ? 'single' : ''}`}>
            {page > 1 && (
                <a
                    href={page - 1 === 1 ? `/${prefixUrl}` : `/${prefixUrl}/page/${page - 1}`}
                    className="prev"
                >
                    « newer posts
                </a>
            )}

            {postsNum === itemsPerPage && (
                <a href={`/${prefixUrl}/page/${page + 1}`} className="next">
                    more interesting posts »
                </a>
            )}
        </nav>
    );
};

export default Pagination;
