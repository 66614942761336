import { Dispatch, FC, SetStateAction } from 'react';
import Cookies from 'universal-cookie';
import './CookieConsent.scss';

const CookieConsent: FC<{ setShowModal: Dispatch<SetStateAction<boolean>> }> = ({
    setShowModal,
}) => {
    const declineCookies = (): void => {
        const cookies = new Cookies(null, { path: '/' });
        cookies.set('jonaypelluz-analytics', false, {
            path: '/',
            expires: new Date(Date.now() + 31536000000),
        });
        setShowModal(false);
    };

    const acceptCookies = (): void => {
        const cookies = new Cookies(null, { path: '/' });
        cookies.set('jonaypelluz-analytics', true, {
            path: '/',
            expires: new Date(Date.now() + 31536000000),
        });
        setShowModal(false);
    };

    return (
        <div id="cookieConsent">
            <p>
                This website uses cookies just for statistics &amp; analysis. By continuing to
                browse, you agree to their use. <a href="/cookies">More info</a>
            </p>
            <div>
                <button onClick={declineCookies} className="decline">
                    Decline
                </button>
                <button onClick={acceptCookies} className="accept">
                    Accept
                </button>
            </div>
        </div>
    );
};

export default CookieConsent;
