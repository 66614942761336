import { Category } from '@model/type';

const Categories: Category[] = [
    {
        name: 'Code',
        slug: 'code',
        description: 'Topics related to programming, software development, and coding tutorials.',
    },
    {
        name: 'General',
        slug: 'general',
        description: 'General discussions covering a wide range of subjects and ideas.',
    },
    {
        name: 'Music',
        slug: 'music',
        description: 'Posts about music, including genres, artists, and playlists.',
    },
    {
        name: 'Vegetarianism',
        slug: 'vegetarianism',
        description: 'Information, recipes, and discussions about a vegetarian lifestyle.',
    },
    {
        name: 'Social Media',
        slug: 'social-media',
        description: 'Exploring trends, strategies, and news in the world of social media.',
    },
];

export default Categories;
