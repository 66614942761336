import Ariadna from '@components/Ariadna';
import BoxContent from '@components/BoxContent';
import Pagination from '@components/Pagination';
import PostList from '@components/PostList';
import Categories from '@config/Category';
import Posts from '@config/Post';
import { FC } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

const Category: FC = () => {
    let pageNum = 1;
    const { page, slug } = useParams<{ page: string; slug: string }>();

    if (page) {
        pageNum = parseInt(page);
    }

    const category = slug ? Categories.find((category) => category.slug === slug) : undefined;

    if (!category) {
        return null;
    }

    const filterPostsByCategory = (category: string) => {
        return Posts.filter((post) => post.category === category);
    };

    const filteredPosts = filterPostsByCategory(category.name);

    const itemsPerPage = 9;
    const indexOfLastPost = pageNum * itemsPerPage;
    const indexOfFirstPost = indexOfLastPost - itemsPerPage;
    const currentPosts = filteredPosts.slice(indexOfFirstPost, indexOfLastPost);

    return (
        <>
            <Helmet>
                <title>{category.name} | jonaypelluz.com</title>
                <meta name="description" content={category.description} />
            </Helmet>
            <section className="category">
                <BoxContent className="category-content">
                    <Ariadna />
                    <PostList posts={currentPosts} category={category.name} />
                    <Pagination
                        postsNum={currentPosts.length}
                        page={pageNum}
                        prefixUrl={`category/${category.slug}`}
                    />
                </BoxContent>
            </section>
        </>
    );
};

export default Category;
