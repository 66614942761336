const FormatDate = (dateString: string): string => {
    const date = new Date(dateString);

    if (isNaN(date.getTime())) {
        throw new Error('Invalid date');
    }

    const monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];

    const monthName = monthNames[date.getMonth()];
    const dayOfMonth = date.getDate();
    const yearValue = date.getFullYear();

    const suffix = (day: number) => {
        if (day >= 11 && day <= 13) return 'th';
        switch (day % 10) {
            case 1:
                return 'st';
            case 2:
                return 'nd';
            case 3:
                return 'rd';
            default:
                return 'th';
        }
    };

    return `${monthName} ${dayOfMonth}${suffix(dayOfMonth)}, ${yearValue}`;
};

export default FormatDate;
