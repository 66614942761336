import { Web } from '@model/type';

const Webs: Web[] = [
    {
        name: 'hormiguero.es',
        url: 'https://hormiguero.es',
        description:
            'Web de información sobre el inicio de colonia de hormigas, las especies de hormigas, deficultad, etc.',
    },
    {
        name: 'mirmecologia.com',
        url: 'https://mirmecologia.com',
        description:
            'Web sobre la mirmecología, información sobre la rama de la entomología dedicada al estudio de las hormigas, cursos y mcuho mas.',
    },
    {
        name: 'jueletrado.com',
        url: 'https://jueletrado.com',
        description:
            'Web pensaba para aprender ortografía con diversos juegos enfocados a que sea divertido el aprendizaje.',
    },
    {
        name: '31ngo.es',
        url: 'https://31ngo.es',
        description:
            'Juego del bingo online para toda la familia, partidas privadas con límete o no de participantes.',
    },
    {
        name: 'paseoperrosen.com',
        url: 'https://paseoperrosen.com',
        description:
            'Proyecto personal de paseo de perros, me gusta la montaña y quién quiera que le pasee el perro que viva por mi zona ahí estoy.',
    },
];

export default Webs;
