import BoxContent from '@components/BoxContent';
import Categories from '@config/Category';
import Posts from '@config/Post';
import { FC } from 'react';
import { Helmet } from 'react-helmet';
import FormatDate from '@utils/FormatDate';
import './Home.scss';

const Home: FC = () => {
    return (
        <>
            <Helmet>
                <title>Welcome to Jonay Pelluz dot Com | jonaypelluz.com</title>
                <meta
                    name="description"
                    content="Explore my blog for insights on Code tutorials, engaging General topics, the latest in Music, healthy Vegetarianism tips, and trends in Social Media. Discover content designed to inform and inspire..."
                />
            </Helmet>
            <section className="home">
                <BoxContent className="home-content">
                    <h1>Welcome to Jonay Pelluz dot Com</h1>
                    <p>
                        This is Jonay's website... and...{' '}
                        <a rel="nofollow" href="/about" title="who the hell is Jonay?">
                            who the hell is Jonay?
                        </a>
                        , I don't even know myself but that's what people call me when they want to
                        speak with me.
                    </p>
                    <p>I do things and I have hobbies like everyone else.</p>
                </BoxContent>
                <div className="home-sidebar home-content">
                    <BoxContent className="home-cv">
                        <p>
                            Curriculum Vitae -{' '}
                            <a href="https://www.linkedin.com/in/jonaypelluz/" target="_blank">
                                LinkedIn in english »
                            </a>
                        </p>
                    </BoxContent>
                    <BoxContent>
                        <h2>
                            <a className="icon-post" href="/category">
                                Category list &raquo;
                            </a>
                        </h2>
                        <ul className="cat-list">
                            {Categories.map((category) => (
                                <li key={category.slug}>
                                    <a
                                        href={`/category/${category.slug}/`}
                                        title={`View all posts filed under ${category.name}`}
                                    >
                                        {category.name}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </BoxContent>
                    <BoxContent className="home-post">
                        <h2>
                            <a
                                className="icon-post"
                                rel="bookmark"
                                href={`/${Posts[0].date}/${Posts[0].slug}/`}
                                title={Posts[0].title}
                            >
                                {Posts[0].title}
                            </a>
                        </h2>
                        <p className="date">{FormatDate(Posts[0].date)}</p>
                    </BoxContent>
                </div>
            </section>
        </>
    );
};

export default Home;
