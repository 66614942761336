import { FC, ReactNode } from 'react';
import './BoxContent.scss';

interface BoxContentProps {
    children: ReactNode;
    className?: string;
}

const BoxContent: FC<BoxContentProps> = ({ children, className }) => (
    <div className={`content-wrapper ${className || ''}`}>
        <div className="content">{children}</div>
    </div>
);

export default BoxContent;
