import { FC, useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import CookieConsent from './CookieConsent';
import './Footer.scss';

const Footer: FC = () => {
    const [showModal, setShowModal] = useState(false);
    useEffect(() => {
        const cookies = new Cookies();
        const cookieValue = cookies.get('jonaypelluz-analytics');

        if (cookieValue === undefined) {
            setShowModal(true);
        } else if (cookieValue === 'false') {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (window as any)['ga-disable-G-W54PED4Z2S'] = true;
        } else if (cookieValue === 'true') {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (window as any)['ga-disable-G-W54PED4Z2S'] = false;
        }
    }, []);

    return (
        <footer>
            <div>
                <p>
                    <a className="first-link" href="/privacy">
                        Privacy Policy
                    </a>
                    <a href="/cookies">Cookie Policy</a>
                </p>
            </div>
            {showModal && <CookieConsent setShowModal={setShowModal} />}
        </footer>
    );
};

export default Footer;
