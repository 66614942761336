import { Post } from '@model/type';
import OldPost from './OldPost';

const Posts: Post[] = [
    {
        title: 'He recuperado mi web',
        slug: 'he-recuperado-mi-web',
        date: '2024/10/07',
        category: 'General',
        content:
            '<p>Después de varios años de haber perdido mi dominio jonaypelluz.com y que alguien lo comprara y pusiera webs raras, he recuperado el dominio y la web en <a href="https://web.archive.org/" target="_blank">WayBack Machine</a> seguramente mi ritmo de publicación no sea muy allá pero bueno estoy contento, no todos los días se recupera algo que pensaba que ya estaba tan perdido.</p>',
        tags: ['notes'],
    },
    ...OldPost.reverse(),
];

export default Posts;
