import React from 'react';
import FormatDate from '@utils/FormatDate';
import './PostCard.scss';

interface PostCardProps {
    title: string;
    date: string;
    tags: string[];
    slug: string;
}

const PostCard: React.FC<PostCardProps> = ({ title, date, tags, slug }) => {
    return (
        <div className="post-card">
            <h2>
                <a className="icon-post" rel="bookmark" href={`/${date}/${slug}/`} title={title}>
                    {title}
                </a>
            </h2>
            <p className="date">{FormatDate(date)}</p>
            <p className="icon-tag metatags">
                {tags.map((tag: string, index: number) => (
                    <React.Fragment key={index}>
                        {tag}
                        {index < tags.length - 1 && ', '}
                    </React.Fragment>
                ))}
            </p>
        </div>
    );
};

export default PostCard;
