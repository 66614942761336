import Ariadna from '@components/Ariadna';
import BoxContent from '@components/BoxContent';
import Webs from '@config/Web';
import { FC } from 'react';
import { Helmet } from 'react-helmet';
import './Portfolio.scss';

const Portfolio: FC = () => {
    return (
        <>
            <Helmet>
                <title>Portfolio | jonaypelluz.com</title>
            </Helmet>
            <section className="portfolio">
                <BoxContent>
                    <Ariadna />
                    <div className="web-list">
                        {Webs.map((web, index) => (
                            <div className="web-card" key={index}>
                                <div className="web-description">
                                    <h3>
                                        <a href={web.url} title={web.name} target="_blank">
                                            {web.name}
                                        </a>
                                    </h3>
                                    <p>{web.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </BoxContent>
            </section>
        </>
    );
};

export default Portfolio;
