import BoxContent from '@components/BoxContent';
import LegalContent from '@config/Legal';
import { FC } from 'react';
import { Helmet } from 'react-helmet';

const Cookies: FC = () => {
    return (
        <>
            <Helmet>
                <title>Política de cookies | jonaypelluz.com</title>
            </Helmet>
            <section>
                <BoxContent>
                    <div dangerouslySetInnerHTML={{ __html: LegalContent.cookies }} />
                </BoxContent>
            </section>
        </>
    );
};

export default Cookies;
