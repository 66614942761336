import { FC } from 'react';
import './Header.scss';

const Header: FC = () => {
    return (
        <header>
            <nav>
                <ul>
                    <li>
                        <a href="/" title="front">
                            front
                            <span>site home</span>
                        </a>
                    </li>
                    <li>
                        <a href="/blog" title="meblog">
                            meblog
                            <span>personal blog</span>
                        </a>
                    </li>
                    <li>
                        <a href="/about" title="about">
                            about
                            <span>personal info, contact</span>
                        </a>
                    </li>
                    <li>
                        <a href="/portfolio" title="portfolio">
                            portfolio
                            <span>websites, designs and photography</span>
                        </a>
                    </li>
                </ul>
            </nav>
        </header>
    );
};

export default Header;
