import Categories from '@config/Category';
import Posts from '@config/Post';
import MainLayout from '@layout/MainLayout';
import About from '@pages/About';
import Blog from '@pages/Blog';
import Category from '@pages/Category';
import CategoryList from '@pages/CategoryList';
import Cookies from '@pages/Cookies';
import Home from '@pages/Home';
import NotFound from '@pages/NotFound';
import Portfolio from '@pages/Portfolio';
import PostPage from '@pages/PostPage';
import Privacy from '@pages/Privacy';
import { LoaderFunctionArgs, createBrowserRouter } from 'react-router-dom';

const router = createBrowserRouter([
    {
        path: '/',
        element: <MainLayout />,
        loader: () => ({
            title: 'Jonay Pelluz dot Com',
            crumbs: [],
        }),
        children: [
            {
                index: true,
                element: <Home />,
                loader: () => ({
                    title: 'Jonay Pelluz dot Com',
                    crumbs: [],
                }),
            },
            {
                path: 'cookies',
                element: <Cookies />,
                loader: () => ({
                    title: 'Cookie Policy',
                    crumbs: [
                        { label: 'Home', path: '/' },
                        { label: 'Cookie Policy', path: '' },
                    ],
                }),
            },
            {
                path: 'privacy',
                element: <Privacy />,
                loader: () => ({
                    title: 'Privacy Policy',
                    crumbs: [
                        { label: 'Home', path: '/' },
                        { label: 'Privacy Policy', path: '' },
                    ],
                }),
            },
            {
                path: 'category',
                element: <CategoryList />,
                loader: () => ({
                    title: 'Category list',
                    crumbs: [
                        { label: 'Meblog', path: '/blog' },
                        { label: 'Category list', path: '' },
                    ],
                }),
            },
            {
                path: 'about',
                element: <About />,
                loader: () => ({
                    title: 'About me',
                    crumbs: [],
                }),
            },
            {
                path: 'portfolio',
                element: <Portfolio />,
                loader: () => ({
                    crumbs: [
                        { label: 'Home', path: '/' },
                        { label: 'Portfolio', path: '' },
                    ],
                }),
            },
            {
                path: 'blog',
                element: <Blog />,
                loader: () => ({
                    title: 'MeBlog',
                    crumbs: [
                        { label: 'Home', path: '/' },
                        { label: 'Meblog', path: '' },
                    ],
                }),
            },
            {
                path: 'blog/page/:page',
                element: <Blog />,
                loader: async ({ params }: LoaderFunctionArgs) => {
                    const pageNum = parseInt(params.page ?? '2');

                    if (pageNum > 1) {
                        return {
                            title: 'MeBlog - Page ${pageNum}',
                            crumbs: [
                                { label: 'Home', path: '/' },
                                { label: 'Meblog', path: '' },
                            ],
                        };
                    }

                    throw new Response('Not Found', { status: 404 });
                },
                errorElement: <NotFound />,
            },
            {
                path: 'category/:slug',
                element: <Category />,
                loader: async ({ params }: LoaderFunctionArgs) => {
                    const slug = params.slug as string;
                    const category = slug
                        ? Categories.find((category) => category.slug === slug)
                        : undefined;

                    if (category) {
                        return {
                            title: category.name,
                            crumbs: [
                                { label: 'Meblog', path: '/blog' },
                                { label: 'Category list', path: '/category' },
                                { label: category.name, path: '' },
                            ],
                        };
                    }

                    throw new Response('Not Found', { status: 404 });
                },
                errorElement: <NotFound />,
            },
            {
                path: 'category/:slug/page/:page',
                element: <Category />,
                loader: async ({ params }: LoaderFunctionArgs) => {
                    const pageNum = parseInt(params.page ?? '2');
                    if (pageNum > 1) {
                        const slug = params.slug as string;
                        const category = slug
                            ? Categories.find((category) => category.slug === slug)
                            : undefined;

                        if (category) {
                            return {
                                title: category.name,
                                crumbs: [
                                    { label: 'Meblog', path: '/blog' },
                                    { label: 'Category list', path: '/category' },
                                    { label: category.name, path: '' },
                                ],
                            };
                        }
                    }

                    throw new Response('Not Found', { status: 404 });
                },
                errorElement: <NotFound />,
            },
            {
                path: ':year/:month/:day/:slug',
                element: <PostPage />,
                loader: async ({ params }: LoaderFunctionArgs) => {
                    const { year, month, day, slug } = params;
                    const post = slug ? Posts.find((post) => post.slug === slug) : undefined;

                    const isDateMatching = post ? post.date === `${year}/${month}/${day}` : false;

                    const category = post
                        ? Categories.find((category) => category.name === post.category)
                        : undefined;

                    if (post && category && isDateMatching) {
                        return {
                            title: post.title,
                            crumbs: [
                                { label: 'Meblog', path: '/blog' },
                                { label: category.name, path: `/category/${category.slug}` },
                                { label: post.title, path: '' },
                            ],
                        };
                    }
                },
                errorElement: <NotFound />,
            },
            {
                path: '*',
                element: <NotFound />,
            },
        ],
    },
]);

export default router;
