import Ariadna from '@components/Ariadna';
import BoxContent from '@components/BoxContent';
import Categories from '@config/Category';
import Posts from '@config/Post';
import { FC, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import FormatDate from '@utils/FormatDate';
import GenerateMetaDescription from '@utils/GenerateMetaDescription';
import './Post.scss';

const PostPage: FC = () => {
    const { year, month, day, slug } = useParams<{
        year: string;
        month: string;
        day: string;
        slug: string;
    }>();
    const post = slug ? Posts.find((post) => post.slug === slug) : undefined;
    if (!post) {
        return null;
    }

    const isDateMatching = post ? post.date === `${year}/${month}/${day}` : false;
    if (!isDateMatching) {
        return null;
    }

    const category = post
        ? Categories.find((category) => category.name === post.category)
        : undefined;
    if (!category) {
        return null;
    }

    return (
        <>
            <Helmet>
                <title>{post.title} | jonaypelluz.com</title>
                <meta name="description" content={GenerateMetaDescription(post.content)} />
            </Helmet>
            <section className="post">
                <BoxContent>
                    <Ariadna />
                    <div className="post-content">
                        <div className="metadata">
                            <p className="date">{FormatDate(post.date)}</p>
                            <p className="icon-category category">
                                <a href={`/category/${category.slug}`}>{category.name}</a>
                            </p>
                        </div>
                        <div className="content">
                            <h1>{post.title}</h1>
                            <p className="icon-tag tags">
                                {post.tags.map((tag: string, index: number) => (
                                    <Fragment key={index}>
                                        {tag}
                                        {index < post.tags.length - 1 && ', '}
                                    </Fragment>
                                ))}
                            </p>
                            <div dangerouslySetInnerHTML={{ __html: post.content }} />
                        </div>
                    </div>
                </BoxContent>
            </section>
        </>
    );
};

export default PostPage;
