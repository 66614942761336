import { LoaderData } from '@model/interface';
import { AriadnaItem } from '@model/type';
import { FC } from 'react';
import { Link, useMatches } from 'react-router-dom';
import './Ariadna.scss';

interface Match {
    data?: LoaderData;
    params: Record<string, string | undefined>;
    pathname: string;
}

const Ariadna: FC = () => {
    const matches = useMatches() as Match[];

    const crumbs: AriadnaItem[] = matches
        .flatMap((match) => match.data?.crumbs || [])
        .filter((crumb: AriadnaItem) => crumb.label);

    if (crumbs.length === 0) {
        return null;
    }

    const lastCrumb = crumbs[crumbs.length - 1];

    return (
        <nav aria-label="breadcrumb" className="ariadna-container">
            <ol className="ariadna-list">
                {crumbs.slice(0, -1).map((item: AriadnaItem) => (
                    <li key={item.path} className="ariadna-item">
                        <Link to={item.path} className="ariadna-link">
                            {item.label}
                        </Link>{' '}
                        &raquo;
                    </li>
                ))}
                <li className="ariadna-item">
                    <span className="ariadna-link ariadna-current">{lastCrumb.label}</span>
                </li>
            </ol>
        </nav>
    );
};

export default Ariadna;
