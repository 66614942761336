import Ariadna from '@components/Ariadna';
import BoxContent from '@components/BoxContent';
import Pagination from '@components/Pagination';
import PostList from '@components/PostList';
import Posts from '@config/Post';
import { FC } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

const Blog: FC = () => {
    let pageNum = 1;
    const { page } = useParams<{ page: string }>();

    if (page) {
        pageNum = parseInt(page);
    }

    const itemsPerPage = 9;

    const indexOfLastPost = pageNum * itemsPerPage;
    const indexOfFirstPost = indexOfLastPost - itemsPerPage;
    const currentPosts = Posts.slice(indexOfFirstPost, indexOfLastPost);

    return (
        <>
            <Helmet>
                <title>Me Blog | jonaypelluz.com</title>
                <meta
                    name="description"
                    content="My personal blog where I write about almost everything"
                />
            </Helmet>
            <section className="blog">
                <BoxContent>
                    <Ariadna />
                    <h1>Posts list</h1>
                    <PostList posts={currentPosts} />
                    <Pagination postsNum={currentPosts.length} page={pageNum} prefixUrl="blog" />
                </BoxContent>
            </section>
        </>
    );
};

export default Blog;
