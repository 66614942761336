import Ariadna from '@components/Ariadna';
import BoxContent from '@components/BoxContent';
import Categories from '@config/Category';
import Posts from '@config/Post';
import { Post } from '@model/type';
import { FC } from 'react';
import './CategoryList.scss';

const CategoryList: FC = () => {
    const postCounts = Posts.reduce((acc: Record<string, number>, post: Post) => {
        acc[post.category] = (acc[post.category] || 0) + 1;
        return acc;
    }, {});

    return (
        <section className="category-list">
            <BoxContent>
                <Ariadna />
                <h1>Category list</h1>
                <ul>
                    {Categories.map((category) => (
                        <li key={category.slug}>
                            <a
                                href={`/category/${category.slug}/`}
                                title={`View all posts filed under ${category.name}`}
                            >
                                {category.name} <span>({postCounts[category.name] || 0})</span>
                            </a>
                        </li>
                    ))}
                </ul>
            </BoxContent>
        </section>
    );
};

export default CategoryList;
