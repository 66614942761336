import Footer from '@components/Footer';
import Header from '@components/Header';
import { FC } from 'react';
import { Outlet } from 'react-router-dom';

const MainLayout: FC = () => {
    return (
        <main>
            <Header />
            <Outlet />
            <Footer />
        </main>
    );
};

export default MainLayout;
