import { AppProvider } from '@context/AppContext';
import { FC } from 'react';
import ReactGA from 'react-ga4';
import { RouterProvider } from 'react-router-dom';
import router from './Routes';

const App: FC = () => {
    ReactGA.initialize('G-W54PED4Z2S');

    return (
        <AppProvider>
            <RouterProvider router={router} />
        </AppProvider>
    );
};

export default App;
