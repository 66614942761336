import BoxContent from '@components/BoxContent';
import { FC } from 'react';
import { Helmet } from 'react-helmet';
import './About.scss';

const About: FC = () => {
    return (
        <>
            <Helmet>
                <title>About me | jonaypelluz.com</title>
                <meta
                    name="description"
                    content="My name is Jonay Pelluz, I am from Canary Islands and I live in Barcelona. I lived in England before."
                />
            </Helmet>
            <section className="about">
                <BoxContent>
                    <h1>About me</h1>
                    <p>
                        At one point in my life, I transitioned into the role of a software
                        developer, and now I find myself navigating the complexities of this world.
                        In my journey, I imagine infusing it with the whimsical touch of pixie dust
                        and the enchanting powers of elf magic.
                    </p>
                    <p>
                        My name is <strong>Jonay Pelluz</strong>, I am from Canary Islands and I
                        live in Barcelona. I lived in England before.
                    </p>
                    <p>
                        I work as <strong>Web Developer</strong> at the moment a, but I am, as well,
                        a <strong>Amateur Myrmecologist</strong> and <b>Father of 3 plus 1</b>.
                    </p>
                    <p>I love to develop websites and everything about Internet.</p>
                    <p>I spend my spare time with my family and personal projects.</p>
                </BoxContent>
            </section>
        </>
    );
};

export default About;
