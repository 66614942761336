import {
    Dispatch,
    FC,
    ReactNode,
    SetStateAction,
    createContext,
    useContext,
    useState,
} from 'react';

interface AppContextType {
    pageSubtitle: string | null;
    setPageSubtitle: Dispatch<SetStateAction<string | null>>;
    resetPageSubtitle: () => void;
}

const AppContext = createContext<AppContextType | undefined>(undefined);

export const AppProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const [pageSubtitle, setPageSubtitle] = useState<string | null>(null);

    const resetPageSubtitle = () => {
        setPageSubtitle(null);
    };

    return (
        <AppContext.Provider value={{ pageSubtitle, setPageSubtitle, resetPageSubtitle }}>
            {children}
        </AppContext.Provider>
    );
};

export const useApp = () => {
    const context = useContext(AppContext);
    if (!context) {
        throw new Error('useApp must be used within an AppProvider');
    }
    return context;
};
