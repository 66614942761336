import { FC } from 'react';
import { Helmet } from 'react-helmet';

const NotFound: FC = () => {
    return (
        <>
            <Helmet>
                <title>Not Found | jonaypelluz.com</title>
            </Helmet>
            <section className="not-found">Not Found</section>
        </>
    );
};

export default NotFound;
